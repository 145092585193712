import { graphql, StaticQuery } from 'gatsby'
import * as React from 'react'
import Img from 'gatsby-image'

import { Col, Container, Image, Row } from 'react-bootstrap'
import Content from '../components/Content'
import Layout from '../layouts/layout'

const Delivery = () => (
    <StaticQuery 
        query={graphql`
        query {
            cdek: file(relativePath: { eq: "cdek.jpeg" }) {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
        }
    `}
        render={data => {
            return (
                <Layout>
                    <Content>
                        <Container>
                            <h1>Доставка</h1>
                            <Row>
                                <Col>
                                    <Img fluid={data.cdek.childImageSharp.fluid} />
                                </Col>
                                <Col xs={8}>
                                    <p>Доставляем автозапчасти и детали кузова по всей территории России транспортной компанией CDEK</p>
                                    <p>Точный расчёт стоимости и сроков доставки осуществляется транспортной компанией.</p>
                                    <p>Все автозапчасти, доставленные транспортными компаниями, застрахованы. При получении деталей проверяйте её состояние.</p>
                               </Col>
                            </Row>
                        </Container>
                    </Content>
                </Layout>
            )
        }}
    />
)

export default Delivery